<template>
  <el-select
    :disabled="status"
    @change="changeValue"
    v-model="type"
    placeholder="选择授权状态"
    size="small"
    style="width: 120px;"
    filterable
    clearable
  >
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value"
      size="small"
    >
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: "select",
  props: {
    status: false,
    options: Array,
    type: "",
  },
  data() {
    return {
      options: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "0",
          label: "未授权",
        },
        {
          value: "1",
          label: "已授权",
        },
        // {
        //   value: "2",
        //   label: "授权失败",
        // },
        // {
        //   value: "3",
        //   label: "已掉线",
        // },
        // {
        //   value: "4",
        //   label: "授权中",
        // },
        {
          label:'无需授权',
          value:'5'
        }
      ],
    };
  },
  mounted() {},
  methods: {
    changeValue() {
      this.$emit("update:type", this.type);
      this.$emit("success", this.type);
    },
  },
};
</script>

<style scoped></style>
<!-- 参数type  回调方法select -->
