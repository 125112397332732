<template>
  <el-drawer v-model="drawer" title="Ai操作日志" destroy-on-close size="1200px">
    <div class="top_select">
      <el-scrollbar :maxHeight="130">
        <div class="search_btn">
          <el-button size="small" icon="Search" type="primary" @click="getList"
            >查询</el-button
          >
        </div>
        <el-form
          label-position="right"
          label-width="80px"
          ref="dataForm"
          size="small"
          :inline="true"
        >
          <div>
            <el-form-item label="操作时间：" prop="time">
              <el-date-picker
                style="width: 160px"
                size="small"
                v-model="listQuery.begin"
                type="datetime"
                placeholder="开始时间"
                :clearable="false"
                :disabled-date="disabledDate"
              />
              -
              <el-date-picker
                style="width: 160px"
                size="small"
                v-model="listQuery.end"
                type="datetime"
                placeholder="结束时间"
                :clearable="false"
                :disabled-date="disabledDate"
              />
            </el-form-item>
            <el-form-item label="操作人员：" prop="userId" label-width="128px">
              <el-input
                v-model="listQuery.userId"
                style="width: 160px"
                clearable
              ></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="动作：" prop="time">
              <el-select
                v-model="listQuery.entityFilter[0].op"
                placeholder="请选择"
                style="width: 160px; margin: 0"
                clearable
              >
                <el-option
                  v-for="item in opOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-select
                v-model="listQuery.entityFilter[0].value"
                placeholder="请选择"
                style="width: 160px; margin-right: 0"
                clearable
                filterable
              >
                <el-option
                  v-for="item in entityOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="公司：" prop="time" label-width="128px">
              <el-select
                v-model="listQuery.actionFilter[0].op"
                placeholder="请选择"
                clearable
                style="width: 160px; margin-left: 0"
              >
                <el-option
                  v-for="item in opOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-input
                v-model="listQuery.actionFilter[0].value"
                style="width: 160px"
                clearable
              ></el-input>
            </el-form-item>
          </div>
          <el-form-item
            v-for="(item, index) in listQuery.targetFilter"
            label="参数："
            :key="item.key"
          >
            <el-select
              v-model="item.op"
              placeholder="请选择"
              clearable
              style="width: 160px; margin-left: 0"
            >
              <el-option
                v-for="item in opOption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-input
              v-model="item.value"
              style="width: 160px; margin-right: 10px"
              clearable
            ></el-input>
            <el-button
              @click.prevent="add(index)"
              size="small"
              type="primary"
              icon="Plus"
              v-if="index == 0"
            >
            </el-button>
            <el-button
              @click.prevent="remove(index)"
              size="small"
              type="danger"
              plain
              v-if="index != 0"
              icon="Delete"
            ></el-button>
          </el-form-item>
        </el-form>
      </el-scrollbar>
    </div>
    <el-table
      :data="list"
      style="width: 100%"
      border
      :height="contentStyleObj"
      v-loading="loading"
    >
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column
        type="index"
        label="序号"
        width="50"
        align="center"
      ></el-table-column>
      <el-table-column prop="action" label="操作公司" min-width="240">
        <template #default="scope">
          <i
            class="iconfont icon-fuzhi1"
            @click="$copyComName(scope.row.action)"
          ></i>
          {{ scope.row.action }}
        </template>
      </el-table-column>
      <el-table-column label="操作人" width="120" prop="userId">
      </el-table-column>
      <el-table-column label="参数" min-width="120" prop="target">
        <template #default="scope">
          <i
            class="iconfont icon-fuzhi1"
            @click="$copyComName(scope.row.target)"
          ></i>
          <el-tooltip effect="dark" placement="top-start">
            <template #content>
              <el-scrollbar :maxHeight="350">
                <div style="max-width: 500px">{{ scope.row.target }}</div>
              </el-scrollbar>
            </template>
            {{ scope.row.target }}
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="行为" min-width="100" prop="entity">
      </el-table-column>
      <el-table-column label="操作时间" width="160">
        <template #default="scope">
          <span>{{
            $parseTime(scope.row.eventTime, "{y}-{m}-{d} {h}:{i}:{s}")
          }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <qzf-pagination
        v-show="total > 0"
        :total="total"
        v-model:page="listQuery.pageNumber"
        v-model:limit="listQuery.pageSize"
        @pagination="getList"
        layout="sizes, prev, pager, next"
      />
    </div>
  </el-drawer>
</template>

<script>
import { getLogNew } from "@/api/company";
export default {
  name: "aiOperationLog",
  data() {
    return {
      disabledDate(time) {
        return time.getTime() > Date.now();
      },
      drawer: false,
      listQuery: {
        pageNumber: 1,
        pageSize: 20,
        entityFilter: [
          {
            op: "like",
            value: "",
          },
        ],
        actionFilter: [
          {
            op: "like",
            value: "",
          },
        ],
        targetFilter: [
          {
            op: "like",
            value: "",
          },
        ],
        category: "v3",
        begin: new Date(),
        end: new Date(),
      },
      list: [],
      contentStyleObj: {},
      loading: false,
      total: 0,
      opOption: [
        {
          label: "模糊查询",
          value: "like",
        },
        {
          label: "全等查询",
          value: "eq",
        },
        {
          label: "不等查询",
          value: "neq",
        },
      ],
      entityOption: [
        {
          label: "工厂账套体检",
          value: "工厂账套体检",
        },
        {
          label: "工厂账套设置",
          value: "工厂账套设置",
        },
        {
          label: "工厂账套迁入",
          value: "工厂账套迁入",
        },
        {
          label: "工厂账套确认",
          value: "工厂账套确认",
        },
        {
          label: "工厂账套迁出",
          value: "工厂账套迁出",
        },

        {
          label: "删除凭证",
          value: "删除凭证",
        },
        {
          label: "删除公司",
          value: "删除公司",
        },
        {
          label: "分配公司",
          value: "分配公司",
        },
        {
          label: "修改公司状态",
          value: "修改公司状态",
        },
        {
          label: "修改客户信息",
          value: "修改客户信息",
        },
        {
          label: "修改客户手机号",
          value: "修改客户手机号",
        },
        {
          label: "上传单据",
          value: "上传单据",
        },
        {
          label: "删除单据",
          value: "删除单据",
        },
        {
          label: "新增修改税种",
          value: "新增修改税种",
        },
        {
          label: "删除税种",
          value: "删除税种",
        },
        {
          label: "税务报表取数",
          value: "税务报表取数",
        },
        {
          label: "会计报表取数",
          value: "会计报表取数",
        },
        {
          label: "发起任务",
          value: "发起任务",
        },
        {
          label: "撤销任务",
          value: "撤销任务",
        },
        {
          label: "结账",
          value: "结账",
        },
        {
          label: "反结账",
          value: "反结账",
        },
        {
          label: "通用设置-凭证",
          value: "通用设置-凭证",
        },
        {
          label: "通用设置-账务&申报设置",
          value: "通用设置-账务&申报设置",
        },
        {
          label: "部门添加&修改",
          value: "部门添加&修改",
        },
        {
          label: "删除部门",
          value: "删除部门",
        },
        {
          label: "人员新增&修改",
          value: "人员新增&修改",
        },
        {
          label: "人员删除",
          value: "人员删除",
        },
        {
          label: "人员修改状态",
          value: "人员修改状态",
        },
        {
          label: "人员绑定角色",
          value: "人员绑定角色",
        },
        {
          label: "人员重置密码(仅主账号)",
          value: "人员重置密码(仅主账号)",
        },
        {
          label: "新增&修改角色",
          value: "新增&修改角色",
        },
        {
          label: "删除角色",
          value: "删除角色",
        },
        {
          label: "角色绑定功能按钮",
          value: "角色绑定功能按钮",
        },
        {
          label: "账簿内(习惯设置)-凭证设置",
          value: "账簿内(习惯设置)-凭证设置",
        },
        {
          label: "集团添加分公司回调第三方",
          value: "集团添加分公司回调第三方",
        },
        {
          label: "结账回调(系统)",
          value: "结账回调(系统)",
        },
        {
          label: "反结账回调(系统)",
          value: "反结账回调(系统)",
        },
        {
          label: "检查回调(系统)",
          value: "检查回调(系统)",
        },
        {
          label: "报税回调(系统)",
          value: "报税回调(系统)",
        },
        {
          label: "删除进项发票(发票关联凭证)",
          value: "删除进项发票(发票关联凭证)",
        },
        {
          label: "删除销项发票(发票关联凭证)",
          value: "删除销项发票(发票关联凭证)",
        },
        {
          label: "删除发票仓库(包含未认证进项)",
          value: "删除发票仓库(包含未认证进项)",
        },
        {
          label: "采集顶呱呱工商银行",
          value: "采集顶呱呱工商银行",
        },
        {
          label:"同步人员(第三方openApi)",
          value:"同步人员(第三方openApi)",
        },
        {
          label:"同步公司(第三方openApi)",
          value:"同步公司(第三方openApi)",
        },
        {
          label:"同步修改公司名称(第三方openApi)",
          value:"同步修改公司名称(第三方openApi)",
        },
        {
          label:"同步修改员工手机号(第三方openApi)",
          value:"同步修改员工手机号(第三方openApi)",
        },
        {
          label:"同步修改部门(第三方openApi)",
          value:"同步修改部门(第三方openApi)",
        },
        {
          label:"同步删除部门(第三方openApi)",
          value:"同步删除部门(第三方openApi)",
        },
        {
          label:"同步删除人员(第三方openApi)",
          value:"同步删除人员(第三方openApi)",
        },
        {
          label:"同步修改公司停报状态(第三方openApi)",
          value:"同步修改公司停报状态(第三方openApi)",
        },
        {
          label:"同步修改公司税号(第三方openApi)",
          value:"同步修改公司税号(第三方openApi)",
        },
        {
          label: "同步修改更新公司负责人(第三方openApi)",
          value: "同步修改更新公司负责人(第三方openApi)",
        },
        {
          label: "新增编辑登录信息",
          value: "新增编辑登录信息",
        },
        {
          label: "删除登录信息",
          value: "删除登录信息",
        },
        {
          label: "修改绑定公司登录信息",
          value: "修改绑定公司登录信息",
        },
        {
          label: "解绑公司登录信息",
          value: "解绑公司登录信息",
        },
        {
          label: "AI迁入迁出回调详细过程参数",
          value: "AI迁入迁出回调详细过程参数",
        },
      ],
    };
  },
  mounted() {
    this.contentStyleObj = this.$getHeight(320);
  },
  methods: {
    init() {
      this.drawer = true;
      this.getList();
    },
    getList() {
      this.loading = true;
      getLogNew(this.listQuery).then((res) => {
        this.loading = false;
        if (res.data.msg == "success") {
          this.list = res.data.data;
          this.total = res.data.data && res.data.data.length ? 9999 : 0;
        }
      });
    },
    add() {
      this.listQuery.targetFilter.push({
        op: "",
        key: Date.now(),
        value: "",
      });
    },
    remove(index) {
      this.listQuery.targetFilter.splice(index, 1);
    },
  },
};
</script>

<style scoped lang="scss">
.top_select {
  margin-bottom: 10px;
  .search_btn {
    position: absolute;
    right: 20px;
  }
}
.icon-fuzhi1 {
  color: var(--themeColor, #17a2b8);
  cursor: pointer;
}
</style>
