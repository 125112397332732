<template>
  <div>
    <!-- 在node_modules\_element-ui@2.4.6@element-ui\lib\theme-chalk\index.css 搜索 el-transfer-panel{ 300px 这个类 -->
    <div>
      <el-dialog :close-on-click-modal="false"
        title="公司绑定客服人员"
        v-model="dialogFormVisible"
        width="760px"
        :before-close="handleClose"
        destroy-on-close>

        <div>
          <addressList v-model:type="address" @change="getList"></addressList>
          <div style="margin-bottom:10px;float: right; margin-right:25px;margin-top: 10px;">
            <el-select filterable clearable placeholder="客服人员" style="width:200px" v-model='userId' @change="changeCostCode()" size="small">
              <el-option v-for="(item,index) in users" :key="index" :label="item.cnName" :value="item.id">
              </el-option>
            </el-select>
          </div>

          <div class="bigdiv">
            <el-transfer 
            v-if="againShow"
            :titles="['公司', '客服人员']"
            :props="{
              key: 'id',
              label: 'name'
            }" 
            filterable
            v-model="value1" 
            :data="tableData">
            </el-transfer>
          </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" @click="save()" size="small">保存</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import addressList from "@/components/Screening/address";
import { getHelpList,getHelpUsers,getHelpSave } from "@/api/help";
export default {
  props: {
    // show:false,
  },
  components:{
    addressList,
  },
  data() {
    return {
      subjectTemp: {
        my_zyywcb: "",
        sccb: "",
      },
      fullscreenLoading: false,
      topCode: '',
      userId:'',
      users: [],
      value1: [],
      againShow: true,
      tableData: [],
      dialogFormVisible: false,
      address:""
    };
  },
  created(){
    this.init()
  },
  methods: {
    init(){
      getHelpUsers({}).then(res=>{
        this.users = res.data.data.list
      })
      this.getList(this.userId)
    },
    getList(id){
      
      this.againShow = false
      getHelpList({userId:id*1,address:this.address}).then(res=>{
        if(res.data.data.list){
          res.data.data.list.map(v=>{
            if(v.isTrial == 1){
              v.name += "(试用)"
            }
          })
        }
        this.tableData = res.data.data.list?res.data.data.list:[]
        if(res.data.data.list2){
          let arr = res.data.data.list2.map(v=>{
            return v.id*1
          })
          this.value1 = arr
        }else{
          this.value1 = []
        }
        this.againShow = true
      })
    },
    changeCostCode(){
      this.getList(this.userId)
    },
    save(){
      let param = {
        userId: this.userId*1,
        ids: this.value1,
      }
      getHelpSave(param).then(res=>{
        if(res.data.msg == "success"){
          this.getList(this.userId)
          this.$message({
            message: '保存成功',
            type: 'success'
          });
          this.dialogFormVisible = false
        }
      })
    },
    // handleClose(done) {
    //   done();
    //   this.$emit('closeSubject','close')
    // }
    underwear() {
      this.dialogFormVisible = true
      this.init()
    },
  }
};
</script>

<style lang="scss" scoped>
.el-transfer-panel__filter .el-input__inner{
  border-radius: 0;
}
.bigdiv{
  overflow: hidden;
  width: 100%;
  
  .leftdiv{
    float: left;
  }
  .rightdiv{
    float: right;
  }
}
.el-table .warning-row {
  background: green !important;
}

.el-table  {
  .success-row{
    background: red !important;
  }
  
}
:deep(.el-transfer-panel) {
  width:38%

}
</style>
