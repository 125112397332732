<template>
  <div class="auto_home app-container">
    <div class="back_btn" @click="toHome">
      <i class="iconfont icon-ai207"></i>
      返回
    </div>
    <div class="tabs">
      <div v-for="(tab, index) in tabs" :key="index" class="tab">
        <div class="tab_flex">
          <div :class="['tab_content', { active: active === tab.value }]">
            <img :src="tab.img">
            <div class="tab_title">
              {{ tab.name }}
            </div>
          </div>
          <div class="triangle-container" v-if="index != 1">
            <img src="../../../assets/sanjiao.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="top_select">
      <div>
        <el-button icon="Refresh" plain type="primary" size="small" class="mr-5" @click="getList()"><span style="margin-top: 2px;">刷新</span></el-button>
        <el-input size="small" placeholder="请输入公司名称" v-model.trim="listQuery.name" style="width:200px"
          @keyup.enter="getList" clearable></el-input>
        <el-button size="small" type="primary" @click="getList" style="margin-right:5px">
          <el-icon>
            <Search />
          </el-icon><span> 搜索</span>
        </el-button>
        <search @success="getList" @cancel="cancel">
          <el-form style="margin:11px 0px 0px 15px;width:360px" class="styleForm" label-width="130px" size="small">
            <el-form-item label="纳税人类型：">
              <selecttaxtype v-model:type="listQuery.type" style="width:140px;margin-left:0"></selecttaxtype>
            </el-form-item>
            <el-form-item label="选择人员：">
              <selectuser code="bookkeeping" v-model:userId="listQuery.userId" style="width:140px;margin-left:0">
              </selectuser>
            </el-form-item>
            <el-form-item label="税局：">
              <selectcity v-model:citys="listQuery.districtCode" style="width:140px;margin-left:0"></selectcity>
            </el-form-item>
            <el-form-item label="授权状态：">
              <selectAccreditStatus v-model:type="listQuery.keepStatus" style="width:140px;margin-left:0">
              </selectAccreditStatus>
            </el-form-item>
            <el-form-item label="标签设置状态：">
              <el-select v-model="listQuery.lableEaStatus" placeholder="请选择标签设置状态" style="width:140px;margin-left: 0px">
                <el-option label="全部" value=""></el-option>
                <el-option label="已设置" value="1"></el-option>
                <el-option label="未设置" value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="标签：">
              <input-common v-model:modelValue="listQuery.labelLogoEa" style="width:140px"></input-common>
            </el-form-item>
          </el-form>
        </search>
      </div>
      <div>
        <el-button size="small" type="primary" @click="moveOut" icon="List" plain
          :disabled="!$buttonStatus('zdjz_ztqc')">账套迁出</el-button>
        <el-button size="small" type="primary" @click="daochu" icon="FolderOpened" :loading="daochuLoading">导出</el-button>
      </div>
    </div>
    <el-table stripe :data="list" :height="contentStyleObj" style="width: 100%" border
      @selection-change="handleSelectionChange" v-loading="loading">
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column align="center" prop="id" type="selection" width="50" />
      <el-table-column sortable="custom" label="编号" align="center" prop="sort" width="70">
        <template #default="scope">
          <span style="color: orange;cursor: pointer;">{{ sortNumber(scope.row.sortCom) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="labelLogoEa" label="标签" width="70" show-overflow-tooltip></el-table-column>
      <el-table-column prop="name" label="公司名称" min-width="240">
        <template #default="scope">
          <TagNameCopy :row="scope.row">
          </TagNameCopy>
        </template>
      </el-table-column>

      <el-table-column prop="districtName" label="税局" min-width="70">
        <template #default="scope">
          {{ $cityFilter(scope.row.district) }}
        </template>
      </el-table-column>
      <el-table-column prop="CurPeriod" label="当前账期" min-width="70">
        <template #default="scope">
          {{ scope.row.CurPeriod }}
        </template>
      </el-table-column>
      <el-table-column label="会计" min-width="60" align="center">
        <template #default="scope">
          <DynamicPopover>
            <template #reference>
              <div @click="poper(scope.row.id)" style="cursor: pointer">
                <i class="iconfont icon-huiji"></i>
              </div>
            </template>
            <KjTable :list="gridData" />
          </DynamicPopover>
        </template>
      </el-table-column>
      <el-table-column align="left" prop="content" label="随手记" min-width="120" v-if="$buttonStatus('jzkh_ssj')"
        show-overflow-tooltip>
        <template #default="scope">
          <span class="xgmStyle" style="cursor:pointer;" @click="smallNotes(scope.row)">记</span>
          <span>{{ scope.row.jotDown }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <qzf-pagination v-show="total > 0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit"
        @pagination="getList" />
    </div>
    <jotDown ref="jotDown" @jotDownUpdate="updateNote" />

  </div>

</template>

<script>
import { testAuto } from '@/api/automatic'
import { eaCompanyAutomaticList, companyUserList } from "@/api/company";
import { exMoveOutList } from '@/api/automatic'
import TagNameCopy from '@/components/table-cell-render/company-name/TagNameCopy'
import DynamicPopover from "@/components/DynamicPopover/DynamicPopover";
import KjTable from "@/components/table-cell-render/KjTable";
import jotDown from '@/components/jotDown'
import selecttaxtype from "@/components/Screening/selecttaxtype";
import selectcity from "@/components/Screening/selectcity";
import selectAccreditStatus from "@/components/Screening/selectAccreditStatus";

export default {
  name: "accountMoveOut",
  components: {
    TagNameCopy,
    DynamicPopover,
    KjTable,
    jotDown,
    selecttaxtype,
    selectcity,
    selectAccreditStatus
  },
  props: {},
  data() {
    return {
      daochuLoading:false,
      drawer: false,
      contentStyleObj: {},
      listQuery: {
        page: 1,
        limit: 20,
      },
      list: [],
      selects: [],
      loading: false,
      active: 3,
      gridData: [],//负责人数据
      callList: [],
      dialogCallVisible: false,
      failCount: 0,
      okCount: 0,
      testLoading: false,
      moveLoading: false,
      pageStatus1: false,
      tabs: [
        {
          name: "第一步：勾选公司",
          value: 1,
          img: require("@/assets/gxgs.png"),
        },
        {
          name: "第二步：账套迁出",
          value: 5,
          img: require("@/assets/ztqc.png"),
        },
      ]
    };
  },
  mounted() {
    this.contentStyleObj = this.$getHeight(330);
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true
      eaCompanyAutomaticList(this.listQuery).then((res) => {
        this.loading = false
        if (res.data.msg == "success") {
          this.list = res.data.data.list ? res.data.data.list : [];
          this.total = res.data.data.total;
        }
      });
    },
    handleSelectionChange(e) {
      this.selects = e
    },

    sortNumber(e) {
      if (!e) {
        return "默认"
      } else {
        return e
      }
    },
    cancel() {
      this.listQuery = {
        page: 1,
        limit: 20,
      }
      this.getList()
    },

    //更新随手记之后的回调
    updateNote(id, text) {
      this.list.map(v => {
        if (v.id == id) {
          v.jotDown = text
        }
      })
    },
    smallNotes(row) {
      this.$refs.jotDown.init(row.name, row.id, row.CurPeriod)
    },
    //账套迁出
    moveOut() {
      if (this.selects.length == 0) {
        this.$qzfMessage('请选择公司', 1)
        return
      }
      this.$confirm('账套迁出后，将取消本企业自动化记账报税，本账期已扣费企业，扣费不再返还并且会清除所有任务记录，确认迁出？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let ids = []
        this.selects.map(v => {
          ids.push(v.id)
        })
        let param = {
          comIds: ids,
          type: '迁出'
        }
        testAuto(param).then(res => {
          if (res.data.msg == 'success') {
            this.$qzfMessage("迁出成功")
            this.getList()
            this.$bus.emit('confirmStatusUpdate')
          }
        })
      });
    },
    // 列表点击
    poper(val) {
      this.gridData = []
      companyUserList({ comId: val }).then(res => {
        this.gridData = res.data.data.list
      })
    },
    toHome() {
      this.$emit('back')
    },
    // 导出
    daochu(){
      // this.daochuLoading = true
      let param = {
        query: this.listQuery,
        ids: this.selects.map(v => {
          return v.id
        })
      }
      exMoveOutList(param).then(res => {
        this.daochuLoading = false
        if (res.data.msg == "success") {
          window.open("https://file.listensoft.net" + res.data.data.url);
        }
      })
    },
  },
};
</script>
<style scoped lang="scss">
.item_icon {
  width: 100%;
  display: inline-block;
  cursor: pointer;

  i {
    display: inline-block;
    line-height: 13px;
    font-size: 13px;
    margin-right: 2px;
  }

  p {
    display: inline-block;
    font-size: 13px;
  }
}

.top_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}

:deep(.el-step__title) {
  font-size: 13px;
  font-weight: 500;
  line-height: 28px;
}

:deep(.el-radio-button__inner) {
  padding: 8px 25px;
}

.tabs {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid rgba(229, 229, 229, 1);
}

.tabs img {
  width: 147px;
  height: 80px;
}

.tab_content {
  width: 150px;
  text-align: center;
  cursor: pointer;
  padding-bottom: 8px;
}

.tab_content.active {
  color: #17a2b8;
  border-bottom: 1px solid #17a2b8;
}

.tab_title {
  font-size: 13px;
}

.tab_flex {
  display: flex;
  align-items: center;
}

.triangle-container {
  margin: 0 30px;

  img {
    width: 26px;
    height: auto;
  }
}

.back_btn {
  display: flex;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 20px;
  color: #17a2b8;
  cursor: pointer;

  .icon-ai207 {
    margin-right: 4px;
  }
}
</style>
