<template>
  <div class="auto_home app-container">
    <div class="back_btn" @click="toHome">
      <i class="iconfont icon-ai207"></i>
      返回
    </div>
    <div class="tabs">
      <div v-for="(tab, index) in tabs" :key="index" class="tab" @click="changeAuto(tab.value)">
        <div class="tab_flex">
          <div :class="['tab_content', { active: active === tab.value }]">
            <img :src="tab.img">
            <div class="tab_title">
              {{ tab.name }}
            </div>
          </div>
          <div class="triangle-container" v-if="index != 3">
            <img src="../../../assets/sanjiao.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="top_select">
      <div>
        <el-button icon="Refresh" plain type="primary" size="small" class="mr-5" @click="getList()"><span
            style="margin-top: 2px;">刷新</span></el-button>
        <el-input size="small" placeholder="请输入公司名称" v-model.trim="listQuery.name" style="width:200px"
          @keyup.enter="getList" clearable></el-input>
        <el-button size="small" type="primary" @click="getList" style="margin-right:5px">
          <el-icon>
            <Search />
          </el-icon><span> 搜索</span>
        </el-button>
        <search @success="getList" @cancel="cancel">
          <el-form style="margin:11px 0px 0px 15px;width:360px" class="styleForm" label-width="130px" size="small">
            <el-form-item label="纳税人类型：">
              <selecttaxtype v-model:type="listQuery.type" style="width:140px;margin-left:0"></selecttaxtype>
            </el-form-item>
            <el-form-item label="选择人员：">
              <selectuser code="bookkeeping" v-model:userId="listQuery.userId" style="width:140px;margin-left:0">
              </selectuser>
            </el-form-item>
            <el-form-item label="税局：">
              <selectcity v-model:citys="listQuery.districtCode" style="width:140px;margin-left:0"></selectcity>
            </el-form-item>
            <el-form-item label="授权状态：">
              <selectAccreditStatus v-model:type="listQuery.keepStatus" style="width:140px;margin-left:0">
              </selectAccreditStatus>
            </el-form-item>
            <el-form-item label="标签设置状态：">
              <el-select v-model="listQuery.lableEaStatus" placeholder="请选择标签设置状态" style="width:140px;margin-left: 0px">
                <el-option label="全部" value=""></el-option>
                <el-option label="已设置" value="1"></el-option>
                <el-option label="未设置" value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="标签：">
              <input-common v-model:modelValue="listQuery.labelLogoEa" style="width:140px"></input-common>
            </el-form-item>
            <el-form-item label="体检状态：" v-if="active == 1">
              <el-select v-model="listQuery.automatic2" placeholder="请选择体检状态" size="small" filterable clearable
                style="width:140px;margin-left:0">
                <el-option label="未体检" value="0">
                </el-option>
                <el-option label="体检通过,未配置" value="2">
                </el-option>
                <el-option label="体检失败" value="4">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否生成记账模型：" v-if="active == 5">
              <el-select v-model="listQuery.mxStatus" placeholder="请选择生成记账模型状态" size="small" filterable clearable
                style="width:140px;margin-left:0">
                <el-option label="全部" value="">
                </el-option>
                <el-option label="已生成" value="2">
                </el-option>
                <el-option label="未生成" value="1">
                </el-option>
                <el-option label="生成失败" value="3">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="配置状态：" v-if="active == 2">
              <el-select v-model="listQuery.automatic2" placeholder="请选择配置状态" size="small" filterable clearable
                style="width:140px;margin-left:0">
                <el-option label="体检通过,未配置" value="2">
                </el-option>
                <el-option label="已配置,未迁入" value="3">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </search>
        <el-select v-model="listQuery.accountingType" placeholder="申报类型" size="small" filterable clearable
          style="width:100px;" @change="getList">
          <el-option label="全部" :value="3">
          </el-option>
          <el-option label="税款申报" :value="2">
          </el-option>
          <el-option label="零申报" :value="1">
          </el-option>
        </el-select>
      </div>
      <div>
        <el-button size="small" type="success" @click="setDefault" icon="Setting" plain :loading="setLoading"
          v-if="active == 2">启用默认配置</el-button>
        <el-button size="small" type="primary" @click="setLabel" icon="Notebook" plain>设置标签</el-button>
        <el-button size="small" type="primary" @click="cancelMoveCom" icon="Remove" plain>退回到第一步</el-button>
        <el-button size="small" type="success" @click="examination" icon="DataAnalysis" v-if="active == 1"
          :loading="testLoading">体检</el-button>
        <el-button size="small" type="success" @click="moveCom" icon="Right" :disabled="!$buttonStatus('zdjz_ztqr')"
          v-if="active == 4" plain :loading="moveLoading">迁入</el-button>
        <el-button size="small" type="success" @click="setMx" icon="Suitcase" v-if="active == 5"
          plain>生成AI记账模型</el-button>
        <el-button size="small" type="primary" @click="daochu" icon="FolderOpened" v-if="active == 1 || active == 5"
          :loading="daochuLoading">导出</el-button>
      </div>
    </div>
    <el-table stripe :data="list" :height="contentStyleObj" style="width: 100%" border
      @selection-change="handleSelectionChange" v-loading="loading">
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column align="center" prop="id" type="selection" width="50" />
      <el-table-column sortable="custom" label="编号" align="center" prop="sort" width="70">
        <template #default="scope">
          <span style="color: orange;cursor: pointer;">{{ sortNumber(scope.row.sortCom) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="labelLogoEa" label="标签" width="70" show-overflow-tooltip></el-table-column>
      <el-table-column prop="name" label="公司名称" min-width="240">
        <template #default="scope">
          <TagNameCopy :row="scope.row">
          </TagNameCopy>
        </template>
      </el-table-column>
      <el-table-column prop="mxStatus" label="AI记账模型" width="120" v-if="active == 5">
        <template #default="scope">
          <el-tooltip :content="scope.row.statusErr" :disabled="scope.row.mxStatus != 3 || !scope.row.statusErr"
            placement="top-start" effect="dark">
            <div class="item_icon">
              <i :class="filterMxStatusIcon(scope.row.mxStatus)"></i>
              <p :class="filterMxStatusWord(scope.row.mxStatus)">{{ filterMxStatus(scope.row.mxStatus) }}</p>
              <i class="iconfont icon-wenhao" style="margin-left: 5px;color: red;cursor: pointer;"
                v-if="scope.row.statusErr && scope.row.mxStatus == 3"></i>
            </div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="districtName" label="税局" width="70">
        <template #default="scope">
          {{ scope.row.districtName }}
        </template>
      </el-table-column>
      <el-table-column prop="CurPeriod" label="当前账期" width="70">
        <template #default="scope">
          {{ scope.row.CurPeriod }}
        </template>
      </el-table-column>
      <el-table-column label="会计" width="60" align="center">
        <template #default="scope">
          <div>
            <el-popover placement="bottom" :width="330" trigger="click">
              <template #reference>
                <div @click="poper(scope.row.id)" style="cursor:pointer;">
                  <i class="iconfont icon-huiji"></i>
                </div>
              </template>
              <el-table :data="gridData">
                <el-table-column width="100" align="center" property="cnName" label="负责人" />
                <el-table-column width="100" align="center" property="roleName" label="角色名称">
                  <template #default="scope">
                    <img src="@/assets/tu/guan.png" alt="" v-if="scope.row.isLeader == 1"
                      style="width: 15px;height: 15px;margin-right: 5px;vertical-align: middle;" />
                    <span style=" display: inline; vertical-align: middle;">{{ scope.row.roleName }}</span>
                  </template>
                </el-table-column>
                <el-table-column width="100" align="center" property="deptName" label="部门名称" />
              </el-table>
            </el-popover>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="this.active == 1 ? '体检状态' : this.active == 2 ? '配置状态' : '迁入状态'" min-width="140"
        v-if="active != 5">
        <template #default="scope">
          <div class="item_icon">
            <p>
              <el-tooltip :disabled="!scope.row.automatic_err" effect="light" placement="top"
                popper-class="tooltip_content">
                <template #content>
                  <div v-for="(item, index) in scope.row.automatic_err" :key="index">
                    <p v-if="item.match('异常')" style="color: #ff8f00;">{{ index + 1 }}、{{ item }}</p>
                    <p v-if="item.match('通过')" style="color: green;">{{ index + 1 }}、{{ item }}</p>
                  </div>
                </template>
                <div>
                  <i :class="filterStatusIcon(scope.row.automatic)"></i>
                  <p :class="filterStatusWord(scope.row.automatic)">{{ filterStatus(scope.row.automatic) }}</p>
                  <i class="iconfont icon-wenhao" style="margin-left: 5px;color: red;cursor: pointer;"
                    v-if="scope.row.automatic_err && scope.row.automatic == 4"></i>
                  <i class="iconfont icon-gantanhao1" style="margin-left: 5px;color: #67c23a;cursor: pointer;"
                    v-else-if="scope.row.automatic_err && scope.row.automatic == 1"></i>
                </div>
              </el-tooltip>
            </p>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="left" prop="content" label="随手记" min-width="120" v-if="$buttonStatus('jzkh_ssj')"
        show-overflow-tooltip>
        <template #default="scope">
          <span class="xgmStyle" style="cursor:pointer;" @click="smallNotes(scope.row)">记</span>
          <span>{{ scope.row.jotDown }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100" align="center" v-if="active != 5">
        <template #default="scope">
          <el-button @click="testSure([scope.row.id], scope.row)" size="small" type="success" plain
            :disabled="scope.row.automatic == 1" :loading="scope.row.loading" v-if="active == 1">体检</el-button>
          <el-button size="small" @click="settingOne(scope.row)" plain type="primary"
            :disabled="scope.row.automatic == 0" v-if="active == 2">配置</el-button>
          <el-button size="small" type="success" @click="moveSure([scope.row.id], scope.row)" v-if="active == 4"
            :loading="scope.row.loading" :disabled="scope.row.automatic == 1 || !$buttonStatus('zdjz_ztqr')"
            plain>迁入</el-button>
          <!-- <el-button size="small" type="success" @click="immigrateSure([scope.row.id])" v-if="active == 4" :disabled="scope.row.automatic != 1" plain>迁出</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <qzf-pagination v-show="total > 0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit"
        @pagination="getList" />
    </div>
  </div>
  <autoSetOne ref="autoSetOne" @success="settingSuccess" idKey="id" />
  <jotDown ref="jotDown" @jotDownUpdate="updateNote" />
  <el-dialog :close-on-click-modal="false" destroy-on-close title="迁入提示" v-model="dialogCallVisible">
    <div style="margin-bottom:10px;">
      <span style="color: #67c23a;margin-right:20px">迁入成功条数:{{ okCount }}</span>
      <span style="color:#F15A24;margin-right:20px">迁入失败条数:{{ failCount }}</span><span>请到第一步查看原因</span>
    </div>
    <div style="height: 450px;overflow: auto;">
      <el-table :data="callList" size="small" border>
        <el-table-column align="left" property="name" label="公司名称"></el-table-column>
      </el-table>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button size="small" @click="dialogCallVisible = false">取消</el-button>
        <el-button size="small" type="primary" @click="skipOnce">第一步：账套体检</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import selecttaxtype from "@/components/Screening/selecttaxtype";

import { eaCompanyList, companyUserList, companyEaSetLable } from "@/api/company";
import { testAuto, companyAutomaticSetEnd, cancelMove, invoiceMx, companyAutomaticSet, eaExCompanyAutomaticList } from '@/api/automatic'
import jotDown from '@/components/jotDown'
import selectcity from "@/components/Screening/selectcity";
import TagNameCopy from '@/components/table-cell-render/company-name/TagNameCopy'
import selectAccreditStatus from "@/components/Screening/selectAccreditStatus";
import autoSetOne from "./autoSetOne.vue";
export default {
  name: "accountMoveIn",
  components: {
    jotDown,
    selecttaxtype,
    selectcity,
    TagNameCopy,
    selectAccreditStatus,
    autoSetOne
  },
  props: {},
  data() {
    return {
      drawer: false,
      contentStyleObj: {},
      listQuery: {
        page: 1,
        limit: 20,
        accountingType: 3,
        status: "2",
        automatic: '6'
      },
      list: [],
      selects: [],
      loading: false,
      active: 1,
      gridData: [],//负责人数据
      callList: [],
      dialogCallVisible: false,
      failCount: 0,
      okCount: 0,
      testLoading: false,
      daochuLoading: false,
      moveLoading: false,
      pageStatus1: false,
      tabs: [
        {
          name: "第一步：账套体检",
          value: 1,
          img: require("@/assets/zttj.png"),
        },
        {
          name: "第二步：生成AI记账模型",
          value: 5,
          img: require("@/assets/scmx.png"),
        },
        {
          name: "第三步：做账习惯配置",
          value: 2,
          img: require("@/assets/xgpz.png"),
        },
        {
          name: "第四步：账套迁入",
          value: 4,
          img: require("@/assets/ztqr.png"),
        },
      ],
      setLoading: false
    };
  },
  mounted() {
    this.pageStatus1 = true
    this.contentStyleObj = this.$getHeight(330);
    this.initBus()
    this.getList();
  },
  methods: {
    initBus() {
      this.$bus.on("refreshTask", () => {
        if (!this.pageStatus1) {
          return
        }
        if (this.selects.length != 0) {
          return
        }
        if (this.list.length == 0) {
          return
        }
        if (this.active != 5) return
        //刷新业务
        let status = false
        this.list.forEach(res => {
          if (res.mxStatus == 1) {
            status = true;
          }
        })
        if (status == true) {
          eaCompanyList(this.listQuery).then(res => {
            this.list = res.data.data.list ? res.data.data.list : [];
            this.total = res.data.data.total;
            this.list.map(v => {
              if (v.automatic_err) {
                v.automatic_err = v.automatic_err.split('#')
              }
            })
          })
        }
      });
    },
    changeAuto(e) {
      this.active = e
      if (this.active == 1) {
        this.listQuery.automatic = '6'
      } else if (this.active == 5) {
        this.listQuery.automatic = '8'
      } else if (this.active == 2) {
        this.listQuery.automatic = '7'
      } else {
        this.listQuery.automatic = '5'
      }
      this.listQuery.automatic2 = ''
      this.getList()
    },
    getList() {
      this.loading = true
      eaCompanyList(this.listQuery).then((res) => {
        this.loading = false
        if (res.data.msg == "success") {
          this.list = res.data.data.list ? res.data.data.list : [];
          this.total = res.data.data.total;
          this.list.map(v => {
            if (v.automatic_err) {
              v.automatic_err = v.automatic_err.split('#')
            }
          })
        }
      });
    },
    handleSelectionChange(e) {
      this.selects = e
    },
    //生成模型
    setMx() {
      if (this.selects.length == 0) {
        this.$qzfMessage("请选择公司", 1)
        return
      }
      let arr = this.selects.map(v => { return { comId: v.id } })
      invoiceMx(arr).then(res => {
        if (res.data.msg == 'success') {
          this.$qzfMessage('任务已发起')
          this.getList()
        }
      })
    },
    //启用默认配置
    setDefault() {
      if (this.selects.length < 2) {
        this.$qzfMessage("至少选择两家公司", 1)
        return
      }
      if (this.selects.length > 1 && this.selects.some((v) => v.type !== this.selects[0].type)) {
        this.$qzfMessage("请选择相同纳税人类型公司", 1);
        return;
      }
      this.setLoading = true
      let param = {
        comIds: this.selects.map(v => { return v.id }),
        list: {
          sblx: 0,
          ywlx: 0,
          gx: 0,
          yh: 0,
          cjqr: 0,
          jxjsfs: 0,
          xxjsfs: 0,
          xjjsfz: 0,
          xjjsfzOut: 0,
          skqr: 1,
          skqrfz: 0,
          chbc: 0,
          myhsBl: 0,
          zj_not_zero: 0,
          zjqr: 0,
          hslx: 0,
          zdzgsz: 0,
          djblsz: 0,
          djbl: 0,
          zgbl: 0,
          bankGr: 0,
          scmll: 0,
          chbcSpec: 0,
          jxyhjsfs: 0,
          xxyhjsfs: 0,
          sbshebao: 0,
          jdcj: 0,
          jdcjBank: 0,
          bankqr: 0,
          shebaoqr: 1,
          salaryqr: 0,
          salarySmall: 100,
          salaryBig: 100,
          gxcj: 0
        }
      }
      if (this.selects[0].type == '1') {
        param.list.jdcj = 1
        param.list.jdcjBank = 1
      }
      companyAutomaticSet(param).then(res => {
        this.setLoading = false
        if (res.data.msg == 'success') {
          this.$confirm('启用默认配置成功，点击跳转账套迁入', '提示', {
            confirmButtonText: '下一步：账套迁入',
            showCancelButton: false,
            type: 'warning'
          }).then(() => {
            this.changeAuto(4)
          }).catch(() => {
            this.getList()
          })
        }
      })
    },
    //取消迁入
    cancelMoveCom() {
      if (this.selects.length == 0) {
        this.$qzfMessage("请选择公司", 1)
        return
      }
      this.$confirm('是否退回到第一步？', '提示', {
        type: 'warning'
      }).then(() => {
        let ids = this.selects.map(v => v.id)
        cancelMove({ comIds: ids }).then(res => {
          if (res.data.msg == 'success') {
            this.$qzfMessage("操作成功")
            this.getList()
          }
        })
      })
    },
    //体检
    examination() {
      if (this.selects.length == 0) {
        this.$qzfMessage("请选择公司", 1)
        return
      }
      let ids = []
      this.selects.map(v => {
        ids.push(v.id)
      })
      this.testSure(ids)
    },
    testSure(ids, row) {
      if (row) {
        row.loading = true
      } else {
        this.testLoading = true
      }
      let param = {
        comIds: ids,
        type: '迁入'
      }
      testAuto(param).then(res => {
        if (row) {
          row.loading = false
        } else {
          this.testLoading = false
        }
        if (res.data.msg == 'success') {
          if (res.data.data && res.data.data.status == '超时') {
            this.$qzfMessage('任务过多，请稍后刷新查看')
            this.getList()
            return
          }
          let all = ids.length
          let fail = res.data.data.errss && res.data.data.errss.length ? res.data.data.errss.length : 0
          if (all == fail) {
            this.$qzfMessage('体检失败，请查看体检状态失败原因', 2)
            this.getList()
          } else {
            let str = '体检数量' + all + '家，成功' + (all - fail) + '家，失败' + fail + '家。'
            this.$confirm(str, '提示', {
              confirmButtonText: '下一步：生成AI记账模型',
              showCancelButton: false,
              type: 'warning'
            }).then(() => {
              this.changeAuto(5)
            }).catch(() => {
              this.getList()
            })
          }
        }
      })
    },
    sortNumber(e) {
      if (!e) {
        return "默认"
      } else {
        return e
      }
    },
    setLabel() {
      if (this.selects.length == 0) {
        this.$qzfMessage("请选择公司", 1)
        return
      }
      this.$prompt('请输入标签', '设置标签', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: '',
        beforeClose: (action, instance, done) => {
          if (action == 'confirm') {
            let value = instance.inputValue;
            let param = {
              ids: this.selects.map(v => v.id),
              labelLogoEa: value
            }
            companyEaSetLable(param).then(res => {
              done();
              if (res.data.msg == "success") {
                this.$qzfMessage("设置成功")
                this.getList()
              }
            })
          } else {
            done()
          }
        }
      })
    },
    settingOne(row) {
      this.$refs.autoSetOne.init(row)
    },
    //配置成功后
    settingSuccess() {
      this.$confirm('公司做账习惯配置成功，点击跳转账套迁入', '提示', {
        confirmButtonText: '下一步：账套迁入',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {
        this.changeAuto(4)
      }).catch(() => {
        this.getList()
      })
    },
    //转到
    moveCom() {
      if (this.selects.length == 0) {
        this.$qzfMessage('请选择公司', 1)
        return
      }
      let str = ''
      this.selects.map(v => {
        if (v.automatic == 1) {
          str = v.name + '已在自动记账客户中，不可操作'
        }
      })
      if (str) {
        this.$qzfMessage(str, 1)
        return
      }
      let ids = []
      this.selects.map(v => {
        ids.push(v.id)
      })
      this.moveSure(ids)
    },
    moveSure(ids, row) {
      if (row) {
        row.loading = true
      } else {
        this.moveLoading = true
      }
      companyAutomaticSetEnd({ comIds: ids }).then(res => {
        if (row) {
          row.loading = false
        } else {
          this.moveLoading = false
        }
        if (res.data.msg == 'success') {
          this.okCount = res.data.data.okCount
          this.failCount = res.data.data.noCount
          if (ids.length == this.okCount) {
            this.$qzfMessage('已转到自动记账')
          } else {
            this.callList = res.data.data.errCompanyName.map(v => ({
              name: v
            }))
            this.dialogCallVisible = true
          }
          this.getList()
          this.$emit('success')
          this.$bus.emit('confirmStatusUpdate')
        }
      })
    },

    skipOnce() {
      this.changeAuto(1)
      this.dialogCallVisible = false
    },
    //更新随手记之后的回调
    updateNote(id, text) {
      this.list.map(v => {
        if (v.id == id) {
          v.jotDown = text
        }
      })
    },
    smallNotes(row) {
      this.$refs.jotDown.init(row.name, row.id, row.CurPeriod)
    },
    filterStatus(type) {
      if (type == 0) {
        return '未体检'
      } else if (type == 1) {
        return '迁移完成'
      } else if (type == 2) {
        return '体检通过,未配置'
      } else if (type == 3) {
        return '已配置,未迁入'
      } else if (type == 4) {
        return '体检失败'
      }
    },
    filterStatusIcon(type) {
      if (type == 0) {
        return 'iconfont icon-gantanhao'
      } else if (type == 1) {
        return 'iconfont icon-duihao'
      } else if (type == 2) {
        return 'iconfont icon-duihao'
      } else if (type == 3) {
        return 'iconfont icon-duihao'
      } else if (type == 4) {
        return 'iconfont icon-cuowu'
      }
    },
    filterStatusWord(type) {
      if (type == 0) {
        return 'wsb'
      } else if (type == 1) {
        return 'sbcg2'
      } else if (type == 2) {
        return 'sbcg2'
      } else if (type == 3) {
        return 'sbcg2'
      } else if (type == 4) {
        return 'sbyc2'
      }
    },
    filterMxStatus(type) {
      if (type == 0) {
        return '未生成'
      } else if (type == 1) {
        return '生成中'
      } else if (type == 2) {
        return '已生成'
      } else if (type == 3) {
        return '生成失败'
      }
    },
    filterMxStatusIcon(type) {
      if (type == 0) {
        return 'iconfont icon-gantanhao'
      } else if (type == 1) {
        return 'iconfont icon-info'
      } else if (type == 2) {
        return 'iconfont icon-duihao'
      } else if (type == 3) {
        return 'iconfont icon-cuowu'
      }
    },
    filterMxStatusWord(type) {
      if (type == 0) {
        return 'wsb'
      } else if (type == 1) {
        return 'sbz'
      } else if (type == 2) {
        return 'sbcg2'
      } else if (type == 3) {
        return 'sbyc2'
      }
    },
    cancel() {
      let originLimit = this.listQuery.limit
      let originAutomatic = this.listQuery.automatic
      this.listQuery = {
        page: 1,
        limit: originLimit,
        accountingType: 3,
        status: "2",
        automatic: originAutomatic,
      }
      this.getList()
    },
    // 列表点击
    poper(val) {
      this.gridData = []
      companyUserList({ comId: val }).then(res => {
        this.gridData = res.data.data.list
      })
    },
    toHome() {
      this.$emit('back')
      this.pageStatus1 = false
    },
    // 导出
    daochu() {
      this.daochuLoading = true
      let param = {
        query: this.listQuery,
        ids: this.selects.map(v => {
          return v.id
        })
      }
      eaExCompanyAutomaticList(param).then(res => {
        this.daochuLoading = false
        if (res.data.msg == "success") {
          window.open("https://file.listensoft.net" + res.data.data.url);
        }
      })
    },
  },
};
</script>
<style scoped lang="scss">
.item_icon {
  width: 100%;
  display: inline-block;
  cursor: pointer;

  i {
    display: inline-block;
    line-height: 13px;
    font-size: 13px;
    margin-right: 2px;
  }

  p {
    display: inline-block;
    font-size: 13px;
  }
}

.top_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}

:deep(.el-step__title) {
  font-size: 13px;
  font-weight: 500;
  line-height: 28px;
}

:deep(.el-radio-button__inner) {
  padding: 8px 25px;
}

.tabs {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid rgba(229, 229, 229, 1);
}

.tabs img {
  width: 147px;
  height: 80px;
}

.tab_content {
  width: 150px;
  text-align: center;
  cursor: pointer;
  padding-bottom: 8px;
}

.tab_content.active {
  color: #17a2b8;
  border-bottom: 1px solid #17a2b8;
}

.tab_title {
  font-size: 13px;
}

.tab_flex {
  display: flex;
  align-items: center;
}

.triangle-container {
  margin: 0 30px;

  img {
    width: 26px;
    height: auto;
  }
}

.back_btn {
  display: flex;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 20px;
  color: #17a2b8;
  cursor: pointer;

  .icon-ai207 {
    margin-right: 4px;
  }
}
</style>
<style>
.tooltip_content {
  background-color: rgb(253, 253, 253) !important;
  border: 1px solid #bbb !important;
}
</style>
