<template>
  <div class="app-container " v-if="show == 0">
    <div class="main_content">
      <div class="each_pic">
        <div class="pic_hint">
          <div class="hint_title">账套迁入</div>
          <div class="mb-5">
            <span class="each_text mr-10">账套体检</span>
            <span class="each_text">生成AI记账模型</span>
          </div>
          <div>
            <span class="each_text mr-10" style="margin-right: 23px;">做账习惯配置</span>
            <span class="each_text">账套迁入</span>
          </div>
        </div>
        <span class="each_title" @click="show = 1">账套迁入</span>
        <img src="../../assets/qc.png">
      </div>
      <div class="each_pic">
        <div class="pic_hint">
          <div class="hint_title">账套迁出</div>
          <div class="each_text">
            选中公司一键迁出
          </div>
        </div>
        <span class="each_title" @click="show = 2">账套迁出</span>
        <img src="../../assets/qr.png">
      </div>
    </div>
  </div>
  <div v-if="show == 1">
    <accountMoveIn ref="accountMoveInRef" @back="show = 0" />
  </div>
  <div v-if="show == 2">
    <accountMoveaOut ref="accountMoveaOutRef" @back="show = 0" />
  </div>
</template>

<script setup>
import { ref } from 'vue'
import accountMoveIn from './components/accountMoveIn.vue'
import accountMoveaOut from './components/accountMoveOut.vue'
const show = ref(0)
const accountMoveInRef = ref()
const accountMoveaOutRef = ref()
</script>

<style scoped lang="scss">
.main_content {
  display: flex;
  align-items: center;
  margin: 40px 0 0 30px;
}

.each_pic {
  width: 390px;
  position: relative;
  margin-right: 30px;

  img {
    width: 100%;
    height: auto;
  }

  .each_title {
    position: absolute;
    bottom: 22%;
    right: 10%;
    font-size: 13px;
    letter-spacing: 1px;
    background-color: #17a2b8;
    padding: 6px 15px;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
  }
}

.pic_hint {
  position: absolute;
  right: 10%;
  top: 40px;

  .hint_title {
    color: transparent;
    text-align: right;
    font-size: 16px;
    background-image: linear-gradient(to right, #107b8b, #12cae7);
    background-clip: text;
    font-weight: 700;
    letter-spacing: 1px;
    margin-bottom: 15px;
  }
}

.each_text {
  font-size: 13px;
}

.each_text::before {
  content: "";
  background: var(--themeColor, #17a2b8);
  display: inline-block;
  width: 8px;
  height: 8px;
  position: relative;
  border-radius: 50%;
  margin-right: 4px;
}
</style>
